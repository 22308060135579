<template>
	<div>
		<div class="flex justify-center py-10">
			<table>
				<thead>
					<tr>
						<td class="border-2 px-4 py-2 font-bold text-center">Posición</td>
						<td class="border-2 px-4 py-2 font-bold text-center">Rubro</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(posicion, k) in listaRubros" :key="k">
						<td class="border-2 px-4 py-2 text-center">
							{{ k + 1 }}
						</td>
						<td class="border-2 px-4 py-2 font-bold">
							<vSelect
								:options="listaRubros"
								v-model="listaRubrosPosicion[k]"
								:placeholder="listaRubrosPosicion[k]"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="flex justify-center mb-6">
			<span>{{ message }}</span>
		</div>
		<div class="flex justify-center mb-8">
			<Botonp
				class="underline mr-10"
				botonpText="Guardar Cambios"
				@click="verificarRubroPosicionNoRepetida()"
			></Botonp>
		</div>
	</div>
</template>

<script>
import gql from "graphql-tag";
import vSelect from "vue-select";
import "@/assets/styles/vue-select.css";
import Botonp from "@/components/botonPrimario";

export default {
	components: {
		vSelect,
		Botonp
	},
	data() {
		return {
			rubros: [],
			listaRubros: [],
			listaRubrosPosicion: [],
			showMessage: false,
			message: ""
		};
	},
	mounted() {
		this.cargaRubro();
	},
	methods: {
		verificarRubroPosicionNoRepetida() {
			let ejecutarMutation = true;

			if (this.listaRubrosPosicion.length >= this.listaRubros.length) {
				for (let i = 0; i < this.listaRubrosPosicion.length; i++) {
					for (let j = i + 1; j < this.listaRubrosPosicion.length; j++) {
						if (
							this.listaRubrosPosicion[i] === this.listaRubrosPosicion[j] ||
							this.listaRubrosPosicion[j] === null
						) {
							ejecutarMutation = false;
						}
					}
				}
				if (ejecutarMutation) {
					this.actualizarTablaPosiciones();
				} else {
					alert("Posiciones con rubros repetidos o vacios, por favor corregir");
				}
			} else {
				alert("Posiciones con rubros repetidos o vacios, por favor corregir");
			}
		},
		async actualizarTablaPosiciones() {
			let listaFinalEditar = [];
			this.showMessage = true;
			this.message = "Guardando, espere por favor";

			for (let i = 0; i < this.listaRubrosPosicion.length; i++) {
				listaFinalEditar.push({
					id: i + 1,
					rubro: this.listaRubrosPosicion[i]
				});
			}
			this.$store.state.isLoading = true;
			await this.$apollo
				.mutate({
					mutation: require("@/graphql/mutations/CrearActualizarPosicionesRubros.gql"),
					variables: {
						data: listaFinalEditar
					},
					fetchPolicy: "no-cache"
				})
				.then(() => {
					this.$store.state.isLoading = false;
					this.showMessage = true;
					this.message = "Tabla actualizada con exito";
				})
				.catch(() => {
					this.$store.state.isLoading = false;
					this.showMessage = true;
					this.message =
						"Error actualizando tabla, por favor vuelva a intentarlo";
				});
		},
		async cargaRubro() {
			this.showMessage = true;
			this.message = "Cargando, por favor espere";
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: gql`
						query rubrosTotal($filtro: String!) {
							rubrosTotal(filtro: $filtro) {
								rubro
							}
						}
					`,
					variables: {
						filtro: "rubro"
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.showMessage = true;
					this.message = "Data cargada con exito";
					this.rubros = data.data.rubrosTotal;
					for (let i = 0; i < this.rubros.length; i++) {
						this.listaRubros.push(this.rubros[i].rubro);
					}
					this.$store.state.isLoading = false;
					this.cargaPosicion();
				})
				.catch(() => {
					this.$store.state.isLoading = false;
					this.showMessage = true;
					this.message = "Error cargando data, por favor vuelva a intentar";
				});
		},
		async cargaPosicion() {
			this.showMessage = true;
			this.message = "Cargando, por favor espere";
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: gql`
						query {
							cargarPosicion {
								id
								rubro
							}
						}
					`,
					fetchPolicy: "no-cache"
				})
				.then(data => {
					let rubrosPosicionRaw = data.data.cargarPosicion;
					this.showMessage = true;
					this.message = "Data cargada con exito";

					for (let i = 0; i < rubrosPosicionRaw.length; i++) {
						if (rubrosPosicionRaw[i].rubro) {
							this.listaRubrosPosicion.push(rubrosPosicionRaw[i].rubro);
						}
					}
					this.$store.state.isLoading = false;
				})
				.catch(() => {
					this.$store.state.isLoading = false;
					this.showMessage = true;
					this.message = "Error cargando data, por favor vuelva a intentar";
				});
		}
	}
};
</script>

<style></style>
